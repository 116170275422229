import React, { useState } from "react"
import { Typography, Card } from "antd"
import { Animated } from "react-animated-css"
import { graphql, Link } from "gatsby"
import { Button } from "antd"
import Img from "gatsby-image"
import Layout from "../../components/Layout"
import TrustedBy from "../../components/TrustedBy"
import DevelopmentProcess from "../../components/WEB_DevelopmentProcess"
import SideForm from "./../../components/SideForm"
import behindImageSmall from "../../images/Group 495@2x.png"
import manual_testing from '../../images/manual_testing-01.png'
import mobile_testing from '../../images/mobile_testing-01.png'
import saas_testing from '../../images/saas_testing-01.png'
import usability_testing from '../../images/usability_testing-01.png'
import web_testing from '../../images/web_testing-01.png'
import ServiceCard from "../../components/ServiceCard"
import ServiceStepsProcess from "../../components/ServiceStepsProcess"

import "../../styles/services.scss"
import "../../styles/animate.css"
import FeaturedWork from "../../components/MOB_FeaturedWork"
import InfoModal from "../../components/InfoModal"

const { Title } = Typography

const Index = ({ data }) => {
  const [isModalVisible, setIsModalVisible] = useState(false)

  const showModal = () => {
      setIsModalVisible(true)
  }

  const handleOk = () => {
      setIsModalVisible(false)
  }

  const handleCancel = () => {
      setIsModalVisible(false)
  }
  const backgroundImage = data.bgImage.childImageSharp.fluid.src
  return (
    <Layout className="services-page-container" data={data} 
    pageTitle={"Visiomate - Quality Assurance Services"}
    descrip = {"Ensure flawless software with our comprehensive quality assurance services. Our expert QA team delivers reliable testing and bug-free solutions. Learn more!"}>
      <div className="top-section"
        style={{ backgroundImage: `url(${backgroundImage})` }}>
        <div className="logo">
          <Link to="/" >
            <Img fluid={data.VMLogoWhite.childImageSharp.fluid} />
          </Link>
        </div>

        <div className="box-wrapper text-and-form-container">
          <div className="information">
            <Animated
              animationIn="fadeInUp"
              animationInDuration={500}
              animationInDelay={300}
              isVisible={true}
            >
              <Title>We Offer Expert Software Consultancy to Drive Your Business Success</Title>
              <br />
              <p>
              Unlock your business's potential with our professional software consultancy services.
              We provide tailored solutions that enhance efficiency, streamline operations, and
              foster innovation. <br />
                <br />
                Our experienced consultants work closely with you to identify challenges and implement
                effective software strategies that align with your goals. Transform your business today
                with our expert guidance!
              </p>
              <TrustedBy showHeading={false} />
            </Animated>
          </div>
          <Animated
            animationIn="fadeInDown"
            animationInDuration={800}
            animationInDelay={500}
            isVisible={true}
            className="form"
          >
            <SideForm />
          </Animated>
        </div>
      </div>
      <div className="services-description-section">
      <div className="service-heading-and-detail">
        <Title>Software Consultancy</Title>
      </div>
      <div className="service-cards-wrapper">
        <ServiceCard img_src={manual_testing} img_alt={'manual_testing'} card_heading={'Specialized Knowledge'} card_para={'Software consultants bring extensive experience and specialized knowledge in software development, helping businesses navigate complex technical challenges and make informed decisions.'}/>
        <ServiceCard img_src={usability_testing} img_alt={'usability_testing'} card_heading={'Customized Solutions'} card_para={'Consultants work closely with businesses to understand their unique requirements, creating custom applications that align with specific goals and workflows.'}/>
        <ServiceCard img_src={web_testing} img_alt={'web_testing'} card_heading={'Improved Project Delivery Times'} card_para={'With their expertise, software consultants streamline development processes, reducing time to market for new applications.'}/>
        <ServiceCard img_src={mobile_testing} img_alt={'mobile_testing'} card_heading={'Risk Mitigation'} card_para={'Consultants analyze potential risks associated with application development and provide strategies to mitigate them, reducing the likelihood of project delays or failures.'}/>
        <ServiceCard img_src={saas_testing} img_alt={'saas_testing'} card_heading={'Access to Latest Technologies'} card_para={'Software consultants keep businesses updated on emerging technologies and trends, helping them leverage innovative solutions that can provide a competitive edge.'}/>
        <ServiceCard card_heading={'Let connect, book a free consultancy session with our experts'} card_button={"Free Consultancy"}/>
      </div>
        <div className="behindimagediv">
            <img
              className="behindImageSmall"
              src={behindImageSmall}
              alt="yellow-triangle"
            />
            <img className="behindImageLarg" src={behindImageSmall} alt="yellow-triangle" />
        </div>
      </div>
      {/* <div className="featured-projects">
        <FeaturedWork data={data} />
      </div> */}
      <DevelopmentProcess title='Software Consultancy Services Process' >
        <ServiceStepsProcess process_heading='Let us know the Requirement' process_para="Share your consultancy requirement with us, we shall swiftly reach out to you to discuss how we can help." process_step_number="1" process_indentify="odd" >
        <Button className="button-our" onClick={showModal}>
          Let’s Discuss
        </Button>
        </ServiceStepsProcess>
        <ServiceStepsProcess process_heading='Connect with our Account Manager' process_para="Our expert will dive into your consultancy requirement and align the best suited resource for you." process_step_number="2" />
        <ServiceStepsProcess process_heading='Meet our Consultant' process_para="Engage with proposed consultant, be satisfied with the expertise of the consultancy." process_step_number="3" process_indentify="odd" iconShow="dark"/>
        <ServiceStepsProcess process_heading='Continual Collaboration' process_para="Expect ongoing support and active communication from our account manager." process_step_number="4" iconShow="dark" />
      </DevelopmentProcess>
      <InfoModal
                companyIsEnabled
                url="http://185.213.27.237:8066/Email/ContactUs"
                isModalVisible={isModalVisible}
                handleOk={handleOk}
                handleCancel={handleCancel}
            />
    </Layout>
  )
}

export default Index

export const  softwareconSolution= graphql`
  query softwareconSolution {
    FarmManagementSystem: file(
      relativePath: { eq: "FarmManagementSystem/pf.jpg" }
    ) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    ISOComplianceManagement: file(
      relativePath: { eq: "ISOComplianceManagement/iso.png" }
    ) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    TenderAssetRecordManagement: file(
      relativePath: { eq: "TenderAssetRecordManagement/tender.jpg" }
    ) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    BigJerry: file(relativePath: { eq: "mobile/BigJerryApp.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    ViztosPos: file(relativePath: { eq: "ViztosPos/vistos_image.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    Odoo1: file(relativePath: { eq: "Odoo/odoo1.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    Odoo2: file(relativePath: { eq: "Odoo/odoo2.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    Odoo3: file(relativePath: { eq: "Odoo/odoo3.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    Custom: file(relativePath: { eq: "Custom/CustDevelopment.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    D365: file(relativePath: { eq: "D365/D365.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    D365_2: file(relativePath: { eq: "D365/D365_2.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    Support: file(relativePath: { eq: "safty.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    Analysis: file(relativePath: { eq: "analysis.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    Analysis2: file(relativePath: { eq: "analysis2.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    Development: file(relativePath: { eq: "development.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    Connect: file(relativePath: { eq: "connect.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    Data: file(relativePath: { eq: "data.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    VMLogoBlack: file(relativePath: { eq: "logo_black_LST.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    VMLogoWhite: file(relativePath: { eq: "logo_white_LST.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    bgImage: file(relativePath: { eq: "Mask_group8.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
